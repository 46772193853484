import type { InsufficientFunds } from "@/types";

const useGameModal = () => {
	const { close, open } = useFunrizeModals();
	const isGuest = useIsGuest();
	const { handleOpenGame } = useOpenGame(open);
	const route = useRoute();
	const { isSweepStakes } = useSwitchMode();
	const { exitFullscreen } = useFullscreenToggle();
	const { sendAdTrigger } = usePopupAds();

	const { data: appInit } = useAppInitData();
	const { data: game, execute: getGame } = useAsyncFetch({
		path: "/rest/game/{id}{mode}/",
		method: "get",
		fetchOptions: {
			path: { id: (route.query.game as string) ?? "", mode: !isGuest.value && route.query.isMoney ? "/play" : "/" }
		},
		options: {
			key: `/rest/game/${route.query.game}/play/`,
			transform: (data) => data?.[0],
			immediate: false
		}
	});
	const { execute: getFreeCoins } = useAsyncFetch({
		path: "/rest/free-coins/receive/",
		method: "get",
		fetchOptions: {
			onResponse: ({ response }) => {
				if (response?._data?.success) {
					getGame();
				}
			}
		},
		options: {
			immediate: false
		}
	});

	const { winnings, tourPoints } = useBalance({ options: { cached: true, immediate: !isGuest.value } });
	const isWinningsUserRegister = ref(false);
	const isInsufficientFunds = ref(false);
	const isInsufficientWithdrawal = ref(false);
	const timeoutId = ref<ReturnType<typeof setTimeout>>();

	const hasPromoOffer = computed(() => !!appInit.value?.bannerPresetPackage);
	const insufficientFundsType = computed<InsufficientFunds>(() => {
		if (!isSweepStakes.value) {
			return hasPromoOffer.value ? "TOURNAMENT_OFFER" : "NO_OFFER";
		}

		if (winnings.value) {
			return "WINNINGS_OFFER";
		}

		return hasPromoOffer.value ? "SWEEPSTAKES_OFFER" : "NO_OFFER";
	});

	const isFreeCoinsAvailable = computed(() => {
		if (isSweepStakes.value) {
			return false;
		}

		return appInit.value?.freeCoins?.isActive && tourPoints.value < appInit.value.freeCoins.minSum!;
	});

	const openCashModal = () => {
		if (winnings.value < 1) {
			window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
		} else {
			window?.$cash?.$router?.push?.("/cash/deposit/");
		}
	};

	const closeModal = async () => {
		if (route.query.game) {
			sendAdTrigger("closeGame");
		}

		await navigateTo(route.path, { replace: true });
		close("LazyOModalGame");
	};

	const registerGameMessages = (event: MessageEvent) => {
		if (event.data.cash?.popups?.show) {
			if (!isGuest.value) {
				const transactions = window?.$cash?.$store?.getters?.["history/transactions"] as Array<Record<string, string>>;
				const transactionOut = transactions?.find((t) => t?.type === "out" && t?.status === "pending");

				exitFullscreen();
				if (transactionOut && winnings.value < 1 && isSweepStakes.value) {
					isInsufficientWithdrawal.value = true;
				} else {
					isInsufficientFunds.value = true;
				}
			} else {
				isWinningsUserRegister.value = true;
				clearTimeout(timeoutId.value);
			}

			dispatchGAEvent({
				event: "open_popup",
				type: isSweepStakes.value ? "winnings" : "coins",
				form_name: "empty_balance",
				location: "gameplay"
			});
		}

		if (event.data.cash?.popups?.create) {
			const eventSource = event.source as typeof window.parent;
			eventSource?.postMessage?.({ cash: { popups: { created: true } } }, "*");
		}

		if (event.data.cash?.open) {
			openCashModal();
		}

		if (event.data.game?.close) {
			closeModal();
		}
	};

	const switchGame = (type: "previous" | "next") => {
		dispatchGAEvent({
			event: "click_button",
			button_name: type,
			location: "gameplay"
		});
	};

	const addGameToLastGames = () => {
		if (!game.value?.game?.id) {
			return;
		}

		const lastGames = appInit.value?.lastGames ?? [];
		const gameIndex = lastGames.indexOf(game.value?.game?.id);
		if (gameIndex > -1) {
			lastGames.splice(gameIndex, 1);
		}
		lastGames.splice(0, 0, game.value?.game?.id);
	};

	watch(
		() => route.query.game,
		(value, oldValue) => {
			if (oldValue && value === undefined) {
				closeModal();
			}
		}
	);

	return {
		game,
		isWinningsUserRegister,
		isInsufficientFunds,
		isInsufficientWithdrawal,
		isFreeCoinsAvailable,
		timeoutId,
		insufficientFundsType,
		addGameToLastGames,
		getGame,
		closeModal,
		openCashModal,
		registerGameMessages,
		handleOpenGame,
		switchGame,
		getFreeCoins
	};
};
export default useGameModal;
